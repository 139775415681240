import employees from '../../modules/Employees/locales/ru-RU';
import analytics from '../../modules/Analytics/locales/ru-RU';
import nav from '../../components/Layout/locales/ru-RU';
import pagination from '../../components/Pagination/locales/ru-RU';
import coordinators from '../../modules/Coordinators/locales/ru-RU';
import rides from '../../modules/Rides/locales/ru-RU';
import reports from '../../modules/Reports/locales/ru-RU';
import promocodes from '../../modules/Promocodes/locales/ru-RU';
import policy from '../../modules/Policy/locales/ru-RU';
import new_order from '../../modules/NewOrder/locales/ru-RU';
import favourites from '../../modules/Favourites/locales/ru-RU';
import profile from '../../components/Profile/locales/ru-RU';
import departments from '../../modules/Departments/locales/ru-RU';

export default {
  notification: {
    title: {
      error: 'Ошибка',
      success: 'Успешно',
      warning: 'Предупреждение',
    },
  },
  errors: {
    error: 'Ошибка',
    'Unknown error': 'Неизвестная ошибка',
    'Bad request': 'Ошибка запроса',
    Unauthorized: 'Требуется авторизация',
    'Not found': 'Не найдено',
    Forbidden: 'Доступ запрещен',
    'Connection refused': 'Отказано в соединении',
    'Server error': 'Ошибка сервера',
    failedGroupDeletion: 'Не удалось удалить группу',
    failedChangeGroupAccess: 'Не удалось изменить доступ к группе',
    failedCCDeletion: 'Не удалось удалить кост-центр',
    failedZoneDeletion: 'Не удалось удалить зону',
    failedCarDeletion: 'Не удалось удалить класс авто',
    failedCarAdding: 'Не удалось добавить класс авто',
    failedUserDeletion: 'Не удалось удалить пользователя',
    failedChangeUserAccess: 'Не удалось изменить доступ к сотруднику',
    noDefaultGroup: 'Группа по умолчанию не найдена',
    failedUserAdding: 'Не удалось добавить пользователя',
    failedCoordinatorDeletion: 'Не удалось удалить координатора',
    failedRuleDeletion: 'Не удалось удалить правило',
    failedRuleAdding: 'Не удалось добавить правило',
    failedRuleEditing: 'Не удалось редактировать правило',
    failedDayDeletion: 'Не удалось удалить день',
    failedDayAdding: 'Не удалось добавить день',
    failedDayEditing: 'Не удалось редактировать день',
    failedAddressDeletion: 'Не удалось удалить адрес',
    failedExport: 'Не удалось загрузить отчет',
    failedUserResendSMS: 'Не удалось отправить сообщение пользователю',
    'Update forbidden': 'Не удалось обновить запись',
    failedToSendFeedback: 'Не удалось отправить сообщение',
  },
  tariff: {
    econom: 'Эконом',
    comfort: 'Комфорт',
    comfortplus: 'Комфорт+',
    exclusive: 'Бизнес',
    delivery: 'Доставка',
    minivan: 'Минивен',
    cargo: 'Грузовой',
    premium: 'Премиум',
    child: 'Детский',
    lux: 'Люкс',
    business_minivan: 'Бизнес минивен',
  },
  actions: {
    edit: 'Редактировать',
    delete: 'Удалить',
    cancel: 'Отменить',
    yes: 'Да',
    save: 'Сохранить',
    confirm: 'Подтвердить',
    add: 'Добавить',
    export: 'Экспорт',
    exporting: 'Экспортирую',
    saving: 'Сохраняю',
    copy: 'Копировать',
    reset: 'Сбросить',
  },
  confirmation: {
    deletion_confirmation: 'Вы действительно хотите удалить выделенную запись?',
    deletion_title: 'Подтвердите удаление',
  },
  order: {
    status: {
      error: 'Ошибка',
      driving: 'В пути',
      waiting: 'Ожидает',
      transporting: 'Выполняется',
      complete: 'Завершен',
      feedback: 'Завершен',
      cancelled: 'Отменен',
      search: 'Поиск',
      new: 'Поиск',
      failed: 'Сбой',
      expired: 'Просрочен',
      scheduling: 'Зарезервирован',
      scheduled: 'Зарезервирован',
    },
  },
  validation: {
    error: {
      phone: 'Номер телефона должен иметь валидное значение',
      phone_length: 'Номер телефона должен содержать {{count}} цифр',
      email: 'Неверно указан адрес электронной почты',
      required: 'Обязательное поле',
      password: 'Пароль должен быть не менее 6 символов',
      "Passwords doesn't match": 'Пароли не совпадают',
      'Wrong current password': 'Неверный текущий пароль',
      invalidSettings: 'Недопустимое значение настроек',
      wrong_format_xlsx: 'Файл должен иметь расширение .xlsx',
      wrong_limits: 'Введите корректные значения полей "от" и "до"',
    },
    not_required: 'необязательное поле',
  },

  pagination,
  nav,
  profile,

  analytics,
  employees,
  coordinators,
  rides,
  reports,
  promocodes,
  policy,
  new_order,
  favourites,
  departments,
};
